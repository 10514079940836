import React, {useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import JsonData from "./data/data.json";
import "./App.css";
import { useStateValue } from "./stateprovider";
import Home from "./routes/home";
import About from "./routes/about"
import Contact from "./routes/contact";
import Product from "./routes/product";

const App = () => {
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    dispatch({
      type: "LOAD_DATA",
      siteData: JsonData
  });
  }, []);
  

  return (
    <Router>
    <Routes>

    <Route path="/our-products" element={<Product data={JsonData} />} />
    <Route path="/contact-us" element={<Contact data={JsonData} />} />
        <Route path="/about-us" element={<About data={JsonData} />} />
        <Route path="/" element={<Home data={JsonData} />} />
    </Routes>
    </Router>

  );
};

export default App;
