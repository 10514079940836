export const initialState = {
    siteData: {},
    nav: "Home"
  };
  export function reducer(state, action) {
    switch (action.type) {
      case "LOAD_DATA":
        return {
          ...state,
          siteData: action.siteData,
        };
        case "NAVIGATION":
            return {
              ...state,
              nav: action.nav,
            };
    
      default:
        return state;
    }
  }