import React, { useEffect } from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { About } from "../components/about";
import { Services } from "../components/services";
import { Testimonials } from "../components/testimonials";
import { Contact } from "../components/contact";
import SmoothScroll from "smooth-scroll";
import { useStateValue } from "../stateprovider";



export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });

const Home = (props) => {
    const [{},dispatch] = useStateValue();
    useEffect(() => {
        dispatch({
            type: "NAVIGATION",
            nav: "Home"
          })
    },[])

    return <div>
      <Navigation data={props.data.Navigation} />
      <Header data={props.data.Header} />
      <About data={props.data.About} />
      <Services data={props.data.Services} />
     
      <Testimonials data={props.data.Testimonials} />
      <Contact data={props.data.Contact} />
    </div>    
}
export default Home