import React, { useState, useEffect } from 'react';
import { Navigation } from '../components/navigation';
import { Link } from "react-router-dom";
import { useStateValue } from "../stateprovider";
import './Solution.css';
import Solution from './solut';

const Product = (props) => {
    const [selectedSolution, setSelectedSolution] = useState(null);
    const solutionsData = [
        {
          id: 1,
          title: "Loan Management System",
          description: "Our Loan Management System efficiently handles the entire lifecycle of loans, from application processing to repayment tracking. It streamlines workflows, enhances risk management, and provides comprehensive reporting for better decision-making.",
          features: ['ERP Integration', 'Authentication', 'Loan Disbursement']
        },
        {
          id: 2,
          title: "Card Management System",
          description: "Our Card Management System offers seamless control over card issuance, activation, and transaction monitoring. It ensures security and convenience for both cardholders and administrators, with features for fraud prevention and real-time card status updates.",
          features: ['ERP Integration', 'Verification', 'Security']
        },
        {
          id: 3,
          title: "MFB Core Banking Application (Bookfinance)",
          description: "BookFinance is a robust Core Banking Application tailored for Microfinance Banks (MFBs). It empowers financial institutions with essential banking functionalities such as customer management, deposit handling, loan processing, and regulatory compliance.",
          features: ['Bank Transfer', 'User Management', 'Money Deposit', 'Foreign Exchange']
        },
        {
          id: 4,
          title: "Academic Record Management System",
          description: "Our Academic Record Management System digitizes and centralizes educational data, simplifying administrative tasks for institutions. From student enrollment to transcript generation, it offers efficient tools for managing academic records securely and accurately.",
          features: ['Secure Record Keeping', 'Result Generation', 'Student & Staff Management']
        },
        {
          id: 5,
          title: "Remita Data Referencing Integration",
          description: "Our integration with Remita Data Referencing enhances data accuracy and verification processes. It allows seamless access to a vast repository of verified information, facilitating quicker decision-making and reducing risks associated with inaccurate data.",
          features: ['Data Collection', 'Data Accuracy & Verification']
        },
        {
          id: 6,
          title: "Paystack Payment Development Integration",
          description: "Our integration with Paystack provides a seamless payment gateway for businesses, enabling secure and convenient online transactions. With features for card payments, bank transfers, and mobile money, it facilitates smooth and reliable payment processing for diverse needs.",
          features: ['Payment Processing', 'Secure Transactions', 'Seamless Transaction']
        }
      ];
      const handleSolutionClick = (id) => {
        setSelectedSolution(selectedSolution === id ? null : id);
      };
    const [{}, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({
            type: "NAVIGATION",
            nav: "product"
          })
    },[])
    return <div className='About'>
        <Navigation data={props.data.Navigation} />
        <div className="product-heading">
        <div className='overlay'>
        <div className='container' style={{ marginTop: 110 + "px" }}>
        <div className='row'>
            <div className='col-12'>
                <div className='top-text-container'>
                    <h1>Our Products</h1>
                    <span>
                        <Link to="/">Home</Link> -
                        <p>Our Products</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
        </div>
        </div>
  
        <div className='product  mag-top'>
        <div className="container">
        <div className="row">
          {solutionsData.map((solution) => (
            <Solution
              key={solution.id}
              solution={solution}
              isSelected={selectedSolution === solution.id}
              onClick={() => handleSolutionClick(solution.id)}
            />
          ))}
        </div>
      </div>
        </div>
        <div id="footer">
        <div className="container text-center">
            <p>
                &copy; {new Date().getFullYear()} SecureLogic.
            </p>
        </div>
    </div>
    </div>
}
export default Product;