import React, { useState, useEffect } from 'react';
import { Navigation } from '../components/navigation';
import { useStateValue } from "../stateprovider";
import {
    Link
} from "react-router-dom";
import { Team } from "../components/Team";


const About = (props) => {
    const [{}, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({
            type: "NAVIGATION",
            nav: "about"
        })
    }, [])
    const [selected, setSelected] = useState(0);
    const values = [
        "Our mission is to empower organizations of all sizes to unlock their full potential through technology. We strive to deliver bespoke software solutions that streamline operations, enhance efficiency, and drive growth, enabling our clients to stay ahead of the competition in an ever-evolving market landscape.",
        "We envision a future where technology serves as a catalyst for positive change across industries. By leveraging the latest advancements in software development, we aim to create transformative solutions that address the unique challenges faced by our clients, paving the way for a more connected, efficient, and secure world.",
        "Over the years, we have had the privilege of working with a diverse portfolio of clients, ranging from startups to Fortune 500 companies, across various industries, including healthcare, finance, retail, manufacturing, and beyond. Our solutions have helped our clients streamline operations, optimize processes, enhance customer experiences, and achieve sustainable growth in an increasingly competitive market."
    ];
    const valuesButton = ["Our Mission", "Our Values", "Our Impact"];
    return <div className='About'>
        <Navigation data={props.data.Navigation} />
        <div className="about-heading">
            <div className='overlay'>
                <div className='container' style={{ marginTop: 110 + "px" }}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='top-text-container'>
                                <h1>About Us</h1>
                                <span>
                                    <Link to="/">Home</Link> -
                                    <p>About us</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className="row">
                <div className='col-lg-6 col-md-12 col-sm-12 mag-top'>
                    <img src="img/about-3.jpg" className="img-responsive" alt="" />{" "}

                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 mag-top'>
                    <p style={{ textAlign: 'justify' }}>
                        {props.data.AboutPage.Header.paragraphOne}
                    </p>

                    <p style={{ textAlign: 'justify' }}>{props.data.AboutPage.Header.paragraphThree}</p>
                </div>
            </div>
        </div>
        <div className='value-section mag-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <div className="about-section">
                            <div className='value-top'>
                                {
                                    valuesButton.map((buttonText, index) => {
                                        return <button key={index}
                                            style={selected === index ? { "borderBottom": "1px solid #4ee712" } : {}}
                                            onClick={() => {
                                                setSelected(index)
                                            }}
                                        >{buttonText}</button>
                                    })
                                }
                            </div>
                            <div className='value-buttom'>
                                {
                                    values.map((value, index) => {
                                        return <div key={index} style={selected === index ? { "display": "flex", } : { "display": "none" }}>
                                            <img src='/img/values.jpg' alt="secure logic" />
                                            <p> {value}</p></div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Team data={props.data.Team} />
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; {new Date().getFullYear()} Secure Logic.
                </p>
            </div>
        </div>
    </div>
}
export default About