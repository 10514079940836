import React from 'react';
import './Solution.css';

function Solution({ solution, isSelected, onClick }) {
  const { title, description, features } = solution;

  return (
    <div className={`col-md-6 solution ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <h2>{title}</h2>
      <div className={`details ${isSelected ? 'expanded' : ''}`}>
        <p>{description}</p>

        <h3>Features</h3>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Solution;
