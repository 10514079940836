import React, { useState, useEffect } from 'react';
import { Navigation } from '../components/navigation';
import { useStateValue } from "../stateprovider";
import {
    Link
} from "react-router-dom";

const initialState = {
    name: "",
    email: "",
    message: "",
};
const Contact = (props) => {
    const [{}, dispatch] = useStateValue();
    const [{ name, email, message }, setState] = useState(initialState);


    useEffect(() => {
        dispatch({
            type: "NAVIGATION",
            nav: "contact"
          })
    },[])

    const contacts = [
        { text: "Z25 Bmuko Dutse Abuja", image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAJpJREFUSEvFleEKgCAMhM8Xi/rRWwfWkxVIioiymzLtn3Tbt9tFOhg/zrg/pgI8gF1wdAM4NK5zBy9ZqHK9BBCh0VHrTBmuOWABD4CtQUlZjaxIyiz0XgJgV5Q7KGvS8D0Z1NauAlBfByHqzoDoHSRNgJTB8IrMAewKJN26DKTJ2PfzHTAXDjv9BeAs/0VssUqnup1UnX+xOeADXPYpGce9CV4AAAAASUVORK5CYII=", headText: "Office Location" },
        { text: "+234 902 053 2253", image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAn1JREFUSEu11cnLzlEUB/DPW4pMKfNUQiEbmS3MYkMZNsTCsEKSEn8AG5QFyYLYGBcksULmKdOCIvNMkbKQOe7RffTze5/H+77Kraen7j33fO853+/3/Or851X3n/P7V4D3OI9LOIaLtR5aDaALjuAbpuN1lcs/SnsHsQwvy7FlgI44i3458C7GVgFpjcEYgtXonP4/YgH2F0GKAJH8dAoYkAKfIF7ZC7VAKnnaYTPm4XO+/6hyWAQ4jGk5+Th8zYB9cA9jCpUEB09xCGvwBbtS3FycQdz/1cYiQJTYIgX2xYP8guDjJPrjRm5LHBU5uI4RaIPbuV3DcaUM8Aw98u9FFYCrGJb3OyTlTMEGdM08rMc6rMJybCoDhNRGYlSWX5zHqwfhZiJ/It6UVDI7CWJvbkuIYSYOpIr3YU4ZYAuWFNERlXRLXIxPfT1VRa5RSYC+RYikO55nHkMgf3AQBAfRJzApJ9uIFdiZSF34L64vktwqJQt1xF744H4y3NBMVpAamo+WNWmVjbYdi4o9xJ7cz/DGrCTlaxmhfSL9KJphag3H15tF0e+HaI4ZWedR2WUMxPckw62p38HX7oJso9rwyauGRkWch/RW4kNu0R20zEnnlxKEY6MLQWjZjL9Cqw27SBZujJ5HW6L8WzlxvDLmTey9S3ET8n6MmHB8vUpqjeuQXwy9cPCnJMWl2PEXdouOD2fH42pWUDlrm+dLyDfWOSwuVFPEKw7KRgNUEgQfazPxsXchuzUAY/7Hd+N4FkG0aHRRUY39ovVMLt2W50+tTj3OycPJv1djASoXYtLGkJuM3uiE4CuUFvt/JK+loiY5taHgplbQUL565z8BlXOFGZPojBoAAAAASUVORK5CYII=", headText: "Phone" },
        { text: "info@securelogic.com.ng", image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAUlJREFUSEvd1T8ohWEUx/GPJIPBZJBSMmCwW2xGpbBSNmVSBhbJRCmTsilWfxaZbBYzA5OQMpgMBsmfe/S8um73ute93eWe5an3vM/5nt/vvM/zNqlzNNW5vsYCjGEN/TXadoUFnESdfIse0FVj8Wz7DXoLAZ8pO4DrKkGhPhT81M5XkAFeMI3Df0LGsYu2coCs7gYW8V4G1JxmF77nx3fzxRTMYButOMMEnkpAOnCAYbxiFjvlFAR0EMfoxiNC/nkBZCjZ2Il7jOaauURmdUkFmap27GMEb5jHVoLMYRMtOMVkLv+cchUDMgtXsJw276V1Kq2RW83rOh7/C5C5EipCTaiKiG6j6+i+MKoCRJEeHOXUfKSZ3JYYfNWASo9FAwPu0ndfqRV/vRfziZn9OslxXa+jr0bCRe5ULxW7rmusW3x7Y/0y62LRF2qEQhkl8rgIAAAAAElFTkSuQmCC", headText: "Company email" }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);
    };

    return <div className='About'>
        <Navigation data={props.data.Navigation} />
        <div className="contact-heading">
        <div className='overlay'>
        <div className='container' style={{ marginTop: 110 + "px" }}>
        <div className='row'>
            <div className='col-12'>
                <div className='top-text-container'>
                    <h1>Contact Us</h1>
                    <span>
                        <Link to="/">Home</Link> -
                        <p>Contact us</p>
                    </span>
                </div>
            </div>

        </div>
    </div>
        </div>
        </div>
      
        <div className='contactMain mag-top'>
            <div className='container'>
                <div className='row'>
                    {
                        contacts.map((contact, index) => {
                            return <div className='col-lg-4 col-md-6 col-sm-12 mag-bottom-3' key={index}>
                                <div className='card-container'>
                                    <div className='card'>
                                    <div className='card-image'>
                                    <img src={contact.image} alt={contact.headText} />
                                    </div>
                                        <div className="text-container">
                                            <h5>{contact.headText}</h5>
                                            <p>{contact.text}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
        <div id="contact" className='mag-top' >
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="section-title" >
                                <h2>Get In Touch</h2>
                                <p>Ready to embark on a transformative journey with Secure Logic? Contact us today to learn more about our services, explore partnership opportunities, or discuss your project requirements with our team of experts.</p>
                            </div>
                            <form name="sentMessage" validate onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Name"
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        id="message"
                                        className="form-control"
                                        rows="4"
                                        placeholder="Message"
                                        required
                                        onChange={handleChange}
                                    ></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div id="success"></div>
                                <button type="submit" className="btn btn-custom btn-lg">
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; {new Date().getFullYear()} Secure Logic.
                </p>
            </div>
        </div>
    </div>
}
export default Contact