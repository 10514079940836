import React from "react";
import { useStateValue } from "../stateprovider";
import {Link} from "react-router-dom"


export const Navigation = (props) => {
  const [{ nav }, dispatch] = useStateValue();

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
          {
            props.data ? 
            <div className="logodiv">
                <img src={props.data.image} alt={props.data.title} /> <p>SecureLogic</p>
                </div>
            : <></>
          }
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
             <li>
              <Link to="/" className={nav === "Home" ? "nav-active" :"page-scroll"}
                onClick={() => {
                  dispatch({
                    type: "NAVIGATION",
                    nav: "Home"
                  })
                }}
              >
                Home
              </Link>
            </li> 
            <li>
              <Link to="/about-us" className={nav === "about" ? "nav-active" :"page-scroll"}
              onClick={() => {
                dispatch({
                  type: "NAVIGATION",
                  nav: "about"
                })
              }}
              >
                About
              </Link>
            </li>
            <li>
              <Link to="/our-products" className={nav === "product" ? "nav-active" :"page-scroll"}
              onClick={() => {
                dispatch({
                  type: "NAVIGATION",
                  nav: "product"
                })
              }}
              
              >
                Our Products
              </Link>
            </li>
          {/**  <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> **/}

          {/*  <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
          </li>*/}
          {/**  <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
          </li> **/}
            <li>
              <Link to="/contact-us" className={nav === "contact" ? "nav-active" :"page-scroll"}
              onClick={() => {
                dispatch({
                  type: "NAVIGATION",
                  nav: "contact"
                })
              }}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
