import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                >
                {
                  props.data.Banner.map((ban, index) => {
                    return   <SwiperSlide key={index}>
                    <div className="swipertext">
                      <h1>
                        {ban.largeText}
                      </h1>
                      <p>
                       {ban.smallText}
                      </p>
                    </div>
                  </SwiperSlide>
                  })
                }
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
